import * as Types from '../types.generated';

import gql from 'graphql-tag';
export type SeoFieldsFragment = { __typename: 'ComponentSeoTags', title?: string | null, description?: string | null, keywords?: string | null, noFollow?: boolean | null, noIndex?: boolean | null };

export const SeoFieldsFragmentDoc = gql`
    fragment seoFields on ComponentSeoTags {
  __typename
  title
  description
  keywords
  noFollow
  noIndex
}
    `;