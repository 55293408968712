import { BaseGameFieldsFragment } from '@cms/graphql/gamesOverviewPage.generated';
import { GtmGameData } from './gtm';

export interface TrackingGame
  extends Omit<BaseGameFieldsFragment, '__typename' | 'sys' | 'tokens'> {
  list?: string;
  position?: number;
  tokens?: string[] | string;
}

const getTrackGame = ({ ...game }: TrackingGame): GtmGameData => ({
  name: game?.title,
  id: game.id,
  price: 1,
  tokens: game.tokens
    ? Array.isArray(game.tokens)
      ? game.tokens.join(', ')
      : game.tokens
    : undefined,
  category: game.categories?.join(', ') || undefined,
  platform: game.platforms?.join(', ') || undefined,
  list: game.list,
  position: game.position,
});

export default getTrackGame;
