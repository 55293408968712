import * as Types from './types.generated';

import gql from 'graphql-tag';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type UserTimelineQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type UserTimelineQuery = { __typename: 'Query', userTimeline?: Array<{ __typename: 'TimelineItem', attributes?: any | null, type?: Types.TimelineItemType | null, startAt: any, expireAt?: any | null, user?: { __typename: 'User', id: string } | null }> | null };


export const UserTimelineDocument = gql`
    query UserTimeline {
  userTimeline {
    user {
      id
    }
    attributes
    type
    startAt
    expireAt
  }
}
    `;

export function useUserTimelineQuery(options?: Omit<Urql.UseQueryArgs<UserTimelineQueryVariables>, 'query'>) {
  return Urql.useQuery<UserTimelineQuery>({ query: UserTimelineDocument, ...options });
};