import { URLS } from '@/config/urls';
import { Heading, Text } from '@chakra-ui/react';
import { LinkBox, LinkBoxList } from '@meritcircle/ui/components';
import React from 'react';
import { TopArticlesSectionProps } from './top-articles-section.types';

export const TopArticlesSection = ({ articles }: TopArticlesSectionProps) => {
  return (
    <LinkBoxList>
      {articles.map((article, index) => (
        <LinkBox key={index} href={`${URLS.ACADEMY}/${article.urlSlug}`}>
          <Heading  size="h4" lineHeight="1.2" noOfLines={2} mb="4"  as='h3' >
            {article.title}
          </Heading>
          <Text color="desiredDawn">{article.overviewDescription}</Text>
        </LinkBox>
      ))}
    </LinkBoxList>
  );
};
