import { TimelineItem, TimelineItemType } from '@/client/graphql/types.generated';
import { GtmEvent, GtmGameData, pushDataLayer } from '@/utils/gtm';
import { LinkProps, TimelineGroupProps, TimelineItemProps } from '@meritcircle/ui/components';

import { format } from 'date-fns';
import { Translate } from 'next-translate';
import { MouseEventHandler } from 'react';

interface TimelineLink {
  title: string;
  path: string;
  onClick?: MouseEventHandler;
  level?: number;
}

export const parseLink = (button: TimelineLink, translation: Translate): LinkProps => ({
  label: translation(
    `timeline:${button.title}`,
    { button, level: button?.level },
    { fallback: button.title },
  ),
  href: button.path || '',
  onClick: button.onClick,
});

export const getTimelineItemLinks = (
  links: TimelineLink[],
  translation: Translate,
): TimelineItemProps['links'] | undefined =>
  links.map(link => link && parseLink(link, translation)).filter(Boolean);

const formatTwitterDescription = (text: string): string =>
  text
    .replace(/(\r\n|\n|\r)/gm, ' ')
    .split(' ')
    .map(part => {
      if (part.startsWith('@')) {
        return `<a href="https://twitter.com/${part.substring(
          1,
        )}" target="_blank" rel="noopener">${part}</a>`;
      }

      if (part.startsWith('http')) {
        return `<a href="${part}" target="_blank" rel="noopener">${part}</a>`;
      }

      return part;
    })
    .join(' ');

export const getTimeLineItem = (
  { type, attributes }: TimelineItem,
  translation: Translate,
  index?: number,
): TimelineItemProps => {
  const {
    title,
    badge,
    // discordId, //@TODO: commented since we haven't decided yet which discord id we should show to the user
    icon,
    image,
    description,
    gameId,
    gameTitle,
    tokens,
    categories,
    platforms,
    primaryButton,
    secondaryButton,
    level,
    giveawayTitle,
    url,
  } = attributes;

  const trackGame: GtmGameData =
    type === TimelineItemType.GameCard
      ? {
          name: gameTitle,
          id: gameId,
          price: 1,
          tokens: tokens?.join(', ') || undefined,
          category: categories?.join(', ') || undefined,
          platform: platforms?.join(', ') || undefined,
          list: 'Timeline',
          position: index,
        }
      : undefined;

  const handleDataLayerPush = trackGame
    ? () =>
        pushDataLayer({
          event: GtmEvent.gameClick,
          games: [trackGame],
        })
    : undefined;

  const handleInView = trackGame
    ? () =>
        pushDataLayer({
          event: GtmEvent.gameImpression,
          games: [trackGame],
        })
    : undefined;

  let parsedDescription = null;
  if (description) {
    parsedDescription =
      type === TimelineItemType.TwitterCard
        ? formatTwitterDescription(description)
        : translation(
            `timeline:${description}`,
            { gameTitle, level, giveawayTitle },
            { fallback: description },
          );
  }

  let parsedTitle = null;

  if (title) {
    parsedTitle = translation(
      `timeline:${title}`,
      { gameTitle, giveawayTitle },
      { fallback: title },
    );
  }

  return {
    title: parsedTitle,
    badges: badge ? [badge] : undefined, //TODO: Check if we want to support an array type from the backend in case we want to support multiple badges.
    // discordId,
    icon,
    image,
    description: parsedDescription,
    links: getTimelineItemLinks(
      [primaryButton && { ...primaryButton, onClick: handleDataLayerPush, level }, secondaryButton],
      translation,
    ),
    onInView: handleInView,
    lineClamp: type !== TimelineItemType.TwitterCard ? 2 : undefined,
    url: type === TimelineItemType.TwitterCard ? url : undefined,
  };
};

export const getTimeLineGroups = (
  items: TimelineItem[],
  translation: Translate,
): TimelineGroupProps[] => {
  let timelinePosition = 0;
  const groups: { date: string; items: TimelineItem[] }[] = items.reduce((accumulator, result) => {
    const startAt = result.startAt.includes('-') ? result.startAt : parseInt(result.startAt);
    const dateTime = new Date(startAt);
    const parsedDate = format(dateTime, 'yyyy-MM-dd');
    const groupIndex = accumulator.findIndex(({ date }) => date === parsedDate);

    if (groupIndex === -1) {
      return [...accumulator, { date: parsedDate, items: [result] }];
    } else {
      const group = accumulator[groupIndex];
      group.items = [...group.items, result];
    }

    return accumulator;
  }, []);

  // This is needed for analytics purposes to know where in the timeline the item is
  return groups.reduce((accumulator, group) => {
    const { items } = group;
    const itemsWithPosition = items.map(item =>
      getTimeLineItem(item, translation, timelinePosition++),
    );
    return [...accumulator, { ...group, items: itemsWithPosition }];
  }, []);
};

export const sortOnStartAt = (current: TimelineItem, next: TimelineItem) =>
  new Date(next.startAt).getTime() - new Date(current.startAt).getTime();
