import { AssetFieldsFragment } from '@cms/graphql/fragments/asset.generated';
import { OpenGraph } from 'next-seo/lib/types';
import { OG_IMAGE_URL } from '@/config/urls';

type GetOpenGraphPropsProps = {
  title: string;
  description?: string;
  url: string;
  image?: Omit<AssetFieldsFragment, '__typename'>;
};

export const getOpenGraphProps = ({
  title,
  description = '',
  url,
  image,
}: GetOpenGraphPropsProps): OpenGraph => {
  const og: OpenGraph = {
    type: 'website',
    url,
    title,
    description,
    images: image
      ? [
          {
            url: image.url,
            width: image.width,
            height: image.height,
            alt: image?.title,
          },
        ]
      : [{ url: OG_IMAGE_URL, alt: 'BeamHub Logo' }],
  };

  return og;
};
