import * as Types from './types.generated';

import gql from 'graphql-tag';
import { LinkedSeoTagsFragmentDoc } from './linkedSeoTagsFragment.generated';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type RewardTaskFragment = { __typename: 'LinkedTaskComponent', slug: string, points: number, title: string, description?: string | null, status: Types.TaskStatus, disabled: boolean, href?: string | null, shouldPerform: boolean, rules: { __typename: 'TaskRules', timePeriod?: Types.TaskRuleTimePeriod | null, action?: Types.TaskRuleAction | null }, taskDescription?: { __typename: 'TaskDescriptionJSON', json?: any | null } | null, form?: Array<{ __typename: 'TaskFormField', fieldId?: string | null, fieldType?: string | null, question?: string | null }> | null };

export type RewardWinnerFragment = { __typename: 'GiveawayWinners', name?: string | null, profilePicture?: string | null };

export type RewardsQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type RewardsQuery = { __typename: 'Query', giveawayOverview: { __typename: 'GiveawayOverview', title: string, metaTitle?: string | null, metaDescription?: string | null, noFollow?: boolean | null, noIndex?: boolean | null, keywords?: string | null, ogImage?: { __typename: 'ContenfulAsset', title: string, width: number, height: number, url: string } | null, subtitle: { __typename: 'SubtitleJSON', json?: any | null }, loggedUserSubtitle: { __typename: 'SubtitleJSON', json?: any | null }, groups?: Array<{ __typename: 'GiveawayOverviewGroup', minimumLevel: number, items?: Array<{ __typename: 'Giveaway', slug: string, description?: string | null, image?: string | null, title: string, startAt?: any | null, endAt?: any | null, millisecondsUntilEnd?: number | null, status?: Types.UserGiveawayStatus | null, tasks?: Array<{ __typename: 'LinkedTaskComponent', slug: string, points: number, title: string, description?: string | null, status: Types.TaskStatus, disabled: boolean, href?: string | null, shouldPerform: boolean, rules: { __typename: 'TaskRules', timePeriod?: Types.TaskRuleTimePeriod | null, action?: Types.TaskRuleAction | null }, taskDescription?: { __typename: 'TaskDescriptionJSON', json?: any | null } | null, form?: Array<{ __typename: 'TaskFormField', fieldId?: string | null, fieldType?: string | null, question?: string | null }> | null }> | null }> | null, xpRange?: { __typename: 'GiveawayXpRange', min: number, max?: number | null } | null }> | null, linkedSeoTags?: { __typename: 'LinkedSeoComponent', title?: string | null, description?: string | null, keywords?: string | null, noFollow?: boolean | null, noIndex?: boolean | null } | null } };

export type RewardQueryVariables = Types.Exact<{
  slug: Types.Scalars['String'];
}>;


export type RewardQuery = { __typename: 'Query', giveaway: { __typename: 'Giveaway', minimumLevel: number, startAt?: any | null, endAt?: any | null, millisecondsUntilEnd?: number | null, status?: Types.UserGiveawayStatus | null, tasks?: Array<{ __typename: 'LinkedTaskComponent', slug: string, points: number, title: string, description?: string | null, status: Types.TaskStatus, disabled: boolean, href?: string | null, shouldPerform: boolean, rules: { __typename: 'TaskRules', timePeriod?: Types.TaskRuleTimePeriod | null, action?: Types.TaskRuleAction | null }, taskDescription?: { __typename: 'TaskDescriptionJSON', json?: any | null } | null, form?: Array<{ __typename: 'TaskFormField', fieldId?: string | null, fieldType?: string | null, question?: string | null }> | null }> | null, winners?: Array<{ __typename: 'GiveawayWinners', name?: string | null, profilePicture?: string | null }> | null } };

export type JoinRewardMutationVariables = Types.Exact<{
  slug: Types.Scalars['String'];
}>;


export type JoinRewardMutation = { __typename: 'Mutation', joinGiveaway: { __typename: 'JoinGiveawayResponse', success: boolean } };

export const RewardTaskFragmentDoc = gql`
    fragment rewardTask on LinkedTaskComponent {
  slug
  points
  rules {
    timePeriod
    action
  }
  title
  description
  taskDescription {
    json
  }
  status
  disabled
  href
  shouldPerform
  form {
    fieldId
    fieldType
    question
  }
}
    `;
export const RewardWinnerFragmentDoc = gql`
    fragment rewardWinner on GiveawayWinners {
  name
  profilePicture
}
    `;
export const RewardsDocument = gql`
    query Rewards {
  giveawayOverview {
    title
    metaTitle
    metaDescription
    ogImage {
      title
      width
      height
      url
    }
    noFollow
    noIndex
    keywords
    subtitle {
      json
    }
    loggedUserSubtitle {
      json
    }
    groups {
      items {
        slug
        description
        image
        title
        startAt
        endAt
        millisecondsUntilEnd
        status
        tasks {
          ...rewardTask
        }
      }
      xpRange {
        min
        max
      }
      minimumLevel
    }
    linkedSeoTags {
      ...linkedSeoTags
    }
  }
}
    ${RewardTaskFragmentDoc}
${LinkedSeoTagsFragmentDoc}`;

export function useRewardsQuery(options?: Omit<Urql.UseQueryArgs<RewardsQueryVariables>, 'query'>) {
  return Urql.useQuery<RewardsQuery>({ query: RewardsDocument, ...options });
};
export const RewardDocument = gql`
    query Reward($slug: String!) {
  giveaway(slug: $slug) {
    minimumLevel
    tasks {
      ...rewardTask
    }
    startAt
    endAt
    millisecondsUntilEnd
    status
    winners {
      ...rewardWinner
    }
  }
}
    ${RewardTaskFragmentDoc}
${RewardWinnerFragmentDoc}`;

export function useRewardQuery(options: Omit<Urql.UseQueryArgs<RewardQueryVariables>, 'query'>) {
  return Urql.useQuery<RewardQuery>({ query: RewardDocument, ...options });
};
export const JoinRewardDocument = gql`
    mutation JoinReward($slug: String!) {
  joinGiveaway(slug: $slug) {
    success
  }
}
    `;

export function useJoinRewardMutation() {
  return Urql.useMutation<JoinRewardMutation, JoinRewardMutationVariables>(JoinRewardDocument);
};