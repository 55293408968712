import { URLS } from '@/config/urls';
import { getBadgeVariant } from '@/utils/games';
import getTrackGame from '@/utils/getTrackGame';
import { GtmEvent, GtmGameData, pushDataLayer } from '@/utils/gtm';
import { BadgeProps, Container } from '@chakra-ui/react';
import { IconName, TileList, TileProps, TitleSection } from '@meritcircle/ui/components';
import { TextLink } from '@meritcircle/ui/components/text-link/text-link';
import useTranslation from 'next-translate/useTranslation';
import { InView } from 'react-intersection-observer';
import { GamesSectionProps } from './games-section.types';

export const GamesSection = ({ games }: GamesSectionProps) => {
  const { t } = useTranslation('dashboard');
  const gamesIterator = (): GtmGameData[] =>
    games.map((game, index) =>
      getTrackGame({ ...game, list: t('games.title'), position: index + 1 }),
    );

  const handleCardImpression = () => {
    pushDataLayer({
      event: GtmEvent.gameImpression,
      games: [...gamesIterator()],
    });
  };

  const handleCardClick = ({ game, position }: { game: TileProps; position: number }) => {
    pushDataLayer({
      event: GtmEvent.gameClick,
      games: [getTrackGame({ ...game, list: t('games.title'), position })],
    });
  };

  const formatBadges = (badge: BadgeProps) => ({
    ...badge,
    variant: getBadgeVariant(badge.children.toString()),
  });

  const gameTiles = games.map((game, index) => ({
    ...game,
    onClick: () => handleCardClick({ game, position: index + 1 }),
    badges: game.badges.map(formatBadges),
  }));

  return (
    <InView threshold={0.5} triggerOnce onChange={inView => inView && handleCardImpression()}>
      <TitleSection
        css={{ marginTop: '0.72rem' }}
        title={t('games.title')}
        actionSlot={
          <TextLink
            href={URLS.GAMES}
            data-testid="homepage-explore-all-games"
            rightIcon={{ icon: IconName.arrowRightFill }}>
            {t('games.button-text')}
          </TextLink>
        }>
        <Container>
          <TileList tiles={gameTiles} />
        </Container>
      </TitleSection>
    </InView>
  );
};
