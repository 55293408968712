import * as Types from './types.generated';

import gql from 'graphql-tag';
export type LinkedSeoTagsFragment = { __typename: 'LinkedSeoComponent', title?: string | null, description?: string | null, keywords?: string | null, noFollow?: boolean | null, noIndex?: boolean | null };

export const LinkedSeoTagsFragmentDoc = gql`
    fragment linkedSeoTags on LinkedSeoComponent {
  title
  description
  keywords
  noFollow
  noIndex
}
    `;