import * as Types from './types.generated';

import gql from 'graphql-tag';
import { AssetFieldsFragmentDoc } from './fragments/asset.generated';
import { SeoFieldsFragmentDoc } from './components/componentSeo.generated';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type DashboardPageQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type DashboardPageQuery = { __typename: 'Query', pageDashboardCollection?: { __typename: 'PageDashboardCollection', items: Array<{ __typename: 'PageDashboard', title?: string | null, metaTitle?: string | null, metaDescription?: string | null, noFollow?: boolean | null, noIndex?: boolean | null, keywords?: string | null, subtitle?: { __typename: 'PageDashboardSubtitle', json: any } | null, backgroundImagesCollection?: { __typename: 'AssetCollection', items: Array<{ __typename: 'Asset', title?: string | null, url?: string | null } | null> } | null, ogImage?: { __typename: 'Asset', title?: string | null, description?: string | null, width?: number | null, height?: number | null, url?: string | null } | null, linkedSeoTags?: { __typename: 'ComponentSeoTags', title?: string | null, description?: string | null, keywords?: string | null, noFollow?: boolean | null, noIndex?: boolean | null } | null, linkedGiveawayPage?: { __typename: 'PageGiveaway', slug?: string | null } | null } | null> } | null };

export type DashboardPageTopArticlesQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type DashboardPageTopArticlesQuery = { __typename: 'Query', pageDashboardCollection?: { __typename: 'PageDashboardCollection', items: Array<{ __typename: 'PageDashboard', topArticlesCollection?: { __typename: 'PageDashboardTopArticlesCollection', items: Array<{ __typename: 'PageAcademyArticle', title?: string | null, urlSlug?: string | null, overviewDescription?: string | null } | null> } | null } | null> } | null };

export type DashboardPageTopGamesQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type DashboardPageTopGamesQuery = { __typename: 'Query', pageDashboardCollection?: { __typename: 'PageDashboardCollection', items: Array<{ __typename: 'PageDashboard', topGamesCollection?: { __typename: 'PageDashboardTopGamesCollection', items: Array<{ __typename: 'PageGame', urlSlug?: string | null, title?: string | null, categories?: Array<string | null> | null, gameTagsCollection?: { __typename: 'PageGameGameTagsCollection', items: Array<{ __typename: 'ComponentGameTag', name?: string | null } | null> } | null, mainImage?: { __typename: 'Asset', url?: string | null, description?: string | null } | null } | null> } | null } | null> } | null };

export type DashboardPageRewardQueryVariables = Types.Exact<{
  slug: Types.Scalars['String'];
}>;


export type DashboardPageRewardQuery = { __typename: 'Query', pageGiveawayCollection?: { __typename: 'PageGiveawayCollection', items: Array<{ __typename: 'PageGiveaway', title?: string | null, slug?: string | null, image?: { __typename: 'Asset', title?: string | null, description?: string | null, width?: number | null, height?: number | null, url?: string | null } | null } | null> } | null };


export const DashboardPageDocument = gql`
    query dashboardPage {
  pageDashboardCollection {
    items {
      title
      subtitle {
        json
      }
      backgroundImagesCollection {
        items {
          title
          url
        }
      }
      metaTitle
      metaDescription
      ogImage {
        ...assetFields
      }
      noFollow
      noIndex
      keywords
      linkedSeoTags {
        ...seoFields
      }
      linkedGiveawayPage {
        slug
      }
    }
  }
}
    ${AssetFieldsFragmentDoc}
${SeoFieldsFragmentDoc}`;
export const DashboardPageTopArticlesDocument = gql`
    query dashboardPageTopArticles {
  pageDashboardCollection {
    items {
      topArticlesCollection(limit: 5) {
        items {
          title
          urlSlug
          overviewDescription
        }
      }
    }
  }
}
    `;
export const DashboardPageTopGamesDocument = gql`
    query dashboardPageTopGames {
  pageDashboardCollection {
    items {
      topGamesCollection(limit: 4) {
        items {
          urlSlug
          title
          categories
          gameTagsCollection(limit: 3) {
            items {
              name
            }
          }
          mainImage {
            url
            description
          }
        }
      }
    }
  }
}
    `;
export const DashboardPageRewardDocument = gql`
    query dashboardPageReward($slug: String!) {
  pageGiveawayCollection(where: {slug: $slug}) {
    items {
      title
      slug
      image {
        ...assetFields
      }
    }
  }
}
    ${AssetFieldsFragmentDoc}`;