import * as Types from '../types.generated';

import gql from 'graphql-tag';
export type AssetFieldsFragment = { __typename: 'Asset', title?: string | null, description?: string | null, width?: number | null, height?: number | null, url?: string | null };

export const AssetFieldsFragmentDoc = gql`
    fragment assetFields on Asset {
  title
  description
  width
  height
  url
}
    `;